import {observer} from "mobx-react";
import AppStore from "../../stores/appStore";
import {uiState} from "../pages/flightPage/flightPageModel";
import ButtonListItem from "./buttonListItem";
import React from "react";
import styles from './flightElementList.module.css';
import common from "../common.module.css";
import {SoundWaveListItem} from "./soundWaveListItem";
import {AtxListItem} from "./atxListItem";
import {GtxListItem} from "./gtxListItem";
export const FlightElementList = observer(() => {

    const model = AppStore.getInstance().uiStore.flightPageModel

    return (<div className={common.tileLevelOne + ' ' + common.noPrint}>
        <ul className={styles.list + ' ' + styles.fadeList}>
            {model.interactionsOnScreen.map((r) => {
                    if (r.kind === 'ATX' || r.kind === 'PRC' || r.kind === 'PRU' || r.kind === 'RBK') return (
                        <AtxListItem  key={r.key} result={r}/>)

                    if (r.kind === 'BTN') 
                        return (<ButtonListItem key={crypto.randomUUID()}  text={r.toDisplay} enabled={false} ></ButtonListItem>)
                
                    if (r.kind === 'SET')
                        return null

                    if (r.kind === 'GTX')
                        return (<GtxListItem key={r.key} result={r} replay={model.replay && model.variables.showReplay}/>)

                    if (r.kind === 'TXT')
                        return (<GtxListItem key={r.key} result={r} replay={null}/>)
                }
            )}

            {model.currentInteraction?.kind === 'BTN' &&  (<ButtonListItem key={crypto.randomUUID()}  text={model.currentInteraction.toDisplay} enabled={true}></ButtonListItem>)}
            {model.currentInteraction && <SoundWaveListItem key={crypto.randomUUID()} />}
            
            {model.uiState === uiState.flightEnded && <ButtonListItem enabled={true} href="/menu" text="Well done! You may leave the frequency. Click here to go to main menu"/>}
        </ul>
    </div>)
    
})