import AppStore from "./appStore";
import { makeAutoObservable, runInAction } from "mobx";

export class IdentData {
	public userId = ""
	public name = "Anonymous"
	public picture = "https://"
	public email: string = ""
	public isAuthenticated: boolean = false
	constructor() {
		makeAutoObservable(this);
	}
}

export class IdentStore {
	private readonly appStore: AppStore
	public state: "noauth" | "waitingotp" | "authenticated" = "noauth";
	public IdentData = new IdentData()

	constructor(appStore: AppStore) {
		this.appStore = appStore
		makeAutoObservable(this);
	}

	public logOut() {
		this.IdentData = new IdentData()
		this.state = "noauth"
		//todo call server to log out
	}

	public async requestOtp(phone: string) {
		const backendUri: string = process.env.REACT_APP_API_URL + '/api/requestotp';
		const formData = new FormData();
		formData.append('phone', phone);
		const requestOptions
			:
			RequestInit = {
			method: 'POST',
			body: formData,
			credentials: "include",
		};
		const response = await fetch(backendUri, requestOptions)
		const data = await response.json()
		console.log(data)
		if (data.status === "ok") {
			runInAction(() => {
				this.state = "waitingotp"
			})

		} else {
			runInAction(() => {
				this.IdentData = new IdentData()
				this.state = "noauth"
			})
		}

		return await response.text;

	}

	public async validateOtp(otp: string) {
		const backendUri: string = process.env.REACT_APP_API_URL + '/api/validateotp';
		const formData = new FormData();
		formData.append('otp', otp);
		const requestOptions
			:
			RequestInit = {
			method: 'POST',
			body: formData,
			credentials: "include",
		};
		const response = await fetch(backendUri, requestOptions);
		const data = await response.json();
		if (data.status === "ok") {
			runInAction(() => {
				this.IdentData.userId = data.userId
				this.IdentData.name = data.name
				this.IdentData.email = data.email
				this.IdentData.isAuthenticated = true
				this.state = "authenticated"
			})
		}
		else {
			runInAction(() => {
				this.IdentData = new IdentData()
				this.state = "noauth"
			})
		}
	}
}