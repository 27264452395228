import {observer} from "mobx-react";
import AppStore from "../../stores/appStore";

export const VoicePage = observer(() => {
	return (
		AppStore.getInstance().voiceStore.voicesForLang() && <ul>
			{AppStore.getInstance().voiceStore.voicesForLang().map((v) => <li> {v.voice.name} - {v.voice.lang} - {v.voice.default ? "true" : "false"} </li> )}
		</ul>		

	)
})