import {MatchItem} from "../../domainModel/flight";
import {getColorShade} from "../../Util";
import React from "react";
import styles from "./matchText.module.css";
import {observer} from "mobx-react";

export const MatchText = observer((props: { match: MatchItem }) => {
    const bgCol = getColorShade((props.match.score / 100)).background
    return (<div className={styles.matchItem} style={{backgroundColor: bgCol}}>
        <span className={styles.text}>{props.match.toDisplay}</span>
    </div>)
})