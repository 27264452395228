import { useEffect, useRef, useState } from "react";

declare global {
  interface String {
    lettersAsIcao(this: string): string;
    digitsAsIndividual(this: String): string;
    asGrammar(this: String): string;
    asRegex(this: String): RegExp;
  }
}

String.prototype.lettersAsIcao = function (this) {
  var str = this.toLowerCase();

  var mapObj = {
    a: 'alpha ',
    b: 'bravo ',
    c: 'charlie ',
    d: 'delta ',
    e: 'echo ',
    f: 'foxtrot ',
    g: 'golf ',
    h: 'hotel ',
    i: 'india ',
    j: 'juliet ',
    k: 'kilo ',
    l: 'lima ',
    m: 'mike ',
    n: 'november ',
    o: 'oscar ',
    p: 'papa ',
    q: 'quebec ',
    r: 'romeo ',
    s: 'sierra ',
    t: 'tango ',
    u: 'uniform ',
    v: 'viktor ',
    w: 'whiskey ',
    x: 'xray ',
    y: 'yankee ',
    z: 'zulu '
  }

  var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
  str = str.replace(re, function (matched) {
    return mapObj[matched as keyof typeof mapObj];
  });

  return str;
}

String.prototype.digitsAsIndividual = function (this: string): string {
  var str = this

  var mapObj = {
    0: '0 ',
    1: '1 ',
    2: '2 ',
    3: '3 ',
    4: '4 ',
    5: '5 ',
    6: '6 ',
    7: '7 ',
    8: '8 ',
    9: 'niner '
  }
  var re = new RegExp(Object.keys(mapObj).join("|"), "gi")
  str = str.replace(re, function (matched) {
    return mapObj[matched as unknown as keyof typeof mapObj]
  })

  return str
}

String.prototype.asGrammar = function (this: string): string {
  var str = this
  return str.replaceAll(',', '').split(' ').join(' | ');
}

String.prototype.asRegex = function (this: string): RegExp {
  var str = this
  return new RegExp('(' + str.split(',').join('|') + ')', 'i')
}

export function getColorShade(number) {
  var hue = (number) * 120;
  var rgbColor = hslToRgb(hue / 360, 1, 0.5);
  var hexColor = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
  var contrastColor = getContrastColor(rgbColor[0], rgbColor[1], rgbColor[2]);
  return { background: hexColor, text: contrastColor };
}

function hslToRgb(h, s, l) {
  var r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function rgbToHex(r, g, b) {
  var componentToHex = function (c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function getContrastColor(r, g, b) {
  var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export { }