import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import React from "react";
import styles from './navButton.module.css';
import common from '../common.module.css'
import {ScoreCircle} from "./scoreCircle";
import AppStore from "../../stores/appStore";

export const NavButton = observer((props: any) => {
    
    const completionRate = AppStore.getInstance().statsStore.SubjectCompletionPercentage(props.subject)
    
    return (
        <Link to={props.link}>
            <div className={styles.navButton + ' ' + (props.bg && styles[props.bg])} id={props.id} onClick={props.click}>
                <div className={styles.fader}>
                    <div className={styles.left}>
                        <h1>{props.heading}</h1>
                        <h3>{props.subheading}</h3>
                    </div>
                    <div className={styles.right}>
                        {completionRate > 0 && completionRate < 101 &&  <ScoreCircle  key={crypto.randomUUID()} value={completionRate} />}
                        
                    </div>
                </div>
            </div>
        </Link>
    )
})

export const NavButtonPlaceholder = observer((props: any) => {

    return (
        <div className={"navButtonPlaceholder"}>
            <h1>{""}</h1>
            <h3>{""}</h3>
        </div>
    )
})