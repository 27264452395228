import {observer} from "mobx-react";
import {NavButton} from "../../common/navButton";
import React from "react";
import common from "../../common.module.css";
import {FlightSelectList} from "../../common/flightSelectList";
import styles from "../mainMenuPage/mainMenuPage.module.css";

export const ReadbackPage = observer(() => {
	return (
		<div className={common.page + ' ' + styles.page + ' ' + styles.navButtonStack}>
			<div className={common.pageHeader}>
				<h1>Practice the read back of ATC instructions and clearances</h1>
				<p><i>Readback: A procedure whereby the receiving station repeats
					a received message or an appropriate part thereof back to
					the transmitting station so as to obtain confirmation of
					correct reception</i></p>
				<p>The flight crew shall be read back to the air traffic controller all safety-related parts of ATC
					clearances and instructions. The following items shall always be read back:</p>
				<ul>
					<li>ATC route clearances</li>
					<li>Clearances and instructions to enter, land on, take off from, hold short of, cross, taxi and
						backtrack on any runway
					</li>
					<li>runway-in-use, altimeter settings, SSR codes, newly assigned communication channels, level
						instructions, heading and speed instructions
					</li>
					<li>transition levels, whether issued by the controller or contained in ATIS broadcasts</li>
					<li>Other clearances or instructions, including conditional clearances and taxi instructions, shall
						be read back or acknowledged in a manner to clearly indicate that they have been understood and
						will be complied with
					</li>
				</ul>
			</div>
			<FlightSelectList subject="readback"/>
		</div>
	);
});
