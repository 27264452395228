import {observer} from "mobx-react";
import styles from "../mainMenuPage/mainMenuPage.module.css";
import {NavButton} from "../../common/navButton";
import React from "react";
import common from "../../common.module.css";
import {FlightSelectList} from "../../common/flightSelectList";

export const IcaoPage = observer(() => {
    return (
        <div className={common.page + ' ' + styles.page + ' ' + styles.navButtonStack}>
            <div className={common.pageHeader}>
                <h1>Learn the letters of the ICAO radio telephony alphabet</h1>
            </div>
            <FlightSelectList subject="Icao"/>
        </div>
    );
});