import AppStore from "./appStore";
import appStore from "./appStore";
import { makeAutoObservable } from "mobx";
import { Voice } from "./voiceStore";

export class SpeechSynthStore {

	pitch: any = 1
	rate: any = 1
	appStore: AppStore
	currentVoice: Voice | null = null

	constructor(appStore: AppStore) {
		this.appStore = appStore
		makeAutoObservable(this)
	}

	public pickRandomVoice(lang: string = 'en') {
		console.log("Picking random voice for", lang)
		const voiceIndex = Math.floor(Math.random() * this.appStore.voiceStore.voicesForLang(lang).length)
		this.currentVoice = this.appStore.voiceStore.voicesForLang(lang)[0] //voiceindexs
	}

	public say(text: string): Promise<any> {
		const c = this.currentVoice
		const pitch = this.pitch
		const rate = this.rate

		console.log("Saying", text, c, pitch, rate)

		return new Promise(function (resolve, reject) {
			if (!c) return resolve("No voice")
			let utterance = new SpeechSynthesisUtterance(text)
			utterance.voice = c.voice;
			utterance.lang = c.voice.lang
			utterance.pitch = pitch
			utterance.rate = rate
			utterance.onend = (event) => {
				resolve(`Utterance has finished being spoken after ${event.elapsedTime} seconds.`);
			};
			speechSynthesis.speak(utterance);
		})
	}
}