import { observer, useLocalObservable } from "mobx-react";
import styles from "./smsLogin.module.css"
import React from "react";
import AppStore from "../../stores/appStore";
import { runInAction } from "mobx";
//import Turnstile, { useTurnstile } from "react-turnstile";

export const SmsLogin = observer(() => {

    const identStore = AppStore.getInstance().identStore
    //	const turnstile = useTurnstile();
    //identStore.logOut()
    const state = useLocalObservable(() => ({ phone: '', otp1: '', otp2: '', otp3: '', otp4: '' }))

    switch (identStore.state) {
        case "noauth":
            return (<div className={styles.smsLogin}>
                <h2>Sign in or sign up.</h2>
                <p> Enter your cell phone number to get a one time password SMS.</p>
                <div className={styles.phoneRow}>
                    <div className={styles.phonePrefix}>+45</div>
                    <input value={state.phone} className={styles.phoneInput} onChange={e => runInAction(() => state.phone = e.target.value)} />
                </div>
                <button className={styles.loginButton} onClick={e => identStore.requestOtp(state.phone)}>Continue</button>
            </div>
            )

            break
        case "waitingotp":
            return (<div className={styles.smsLogin}>
                <h2>Sign in or sign up.</h2>
                <p>Enter the OTP you received over SMS</p>
                <div className={styles.phoneRow}>
                    <input value={state.otp1} className={styles.otpInput} maxLength={1} onChange={e => runInAction(() => state.otp1 = e.target.value)} />
                    <input value={state.otp2} className={styles.otpInput} maxLength={1} onChange={e => runInAction(() => state.otp2 = e.target.value)} />
                    <input value={state.otp3} className={styles.otpInput} maxLength={1} onChange={e => runInAction(() => state.otp3 = e.target.value)} />
                    <input value={state.otp4} className={styles.otpInput} maxLength={1} onChange={e => runInAction(() => state.otp4 = e.target.value)} />
                </div>
                <button className={styles.loginButton} onClick={e => identStore.validateOtp(state.otp1 + state.otp2 + state.otp3 + state.otp4)}>Login</button>
            </div>
            )
            break
        case "authenticated":
            return (<div className={styles.smsLogin}>
                <h2>Sign in or sign up.</h2>
                <p> You are logged in as {identStore.IdentData.name}</p>
            </div>
            )
            break
    }
});
