import {observer} from "mobx-react";
import React from "react";
import {FlightSelectList} from "../../common/flightSelectList";
import common from '../../common.module.css'

export const NavigationPage = observer(() => {
    return (
        <div className={common.page}>
            <FlightSelectList subject="Navigation"/>
        </div>
    );
});