import AppStore from "./appStore";
import {makeAutoObservable} from "mobx";
import {RecognitionResult} from "../domainModel/flight";

export class StatsData {

	public recognitionStats: {} = {}
	public flights: any[] = []
	constructor() {
		makeAutoObservable(this);
	}
		
	public fromJson(json: any) {
		this.flights = json?.flights?.slice() ?? []
		this.recognitionStats = {...json.recognitionStats}
	}
	
	//https://mobx.js.org/observable-state.html#converting-observables-back-to-vanilla-javascript-collections
	/*
	public toJSON() {
		//de-observify
		return {
			map: new Map(this._map),
			records: this._records.slice(),
			recognitionStats: {...this._recognitionStats}
		}
	}*/
	
	public CollectStatsForRecognitionResult(result: any) {
		if (result.kind && result.score) {
			if (!this.recognitionStats[result.kind]) this.recognitionStats[result.kind] = []
			this.recognitionStats[result.kind].push(result.score)
		}
		if (result.matches) {
			result.matches.forEach((r) => {
				if (r.kind && r.score) {
					if (!this.recognitionStats[r.kind]) this.recognitionStats[r.kind] = []
					this.recognitionStats[r.kind].push(r.score)
				}
			})
		}
	}
	
	public CollectStatsForCompletedFlight(flightId: string, subject: string) {
		if (!this.flights.find(f => f.flightId === flightId))
			this.flights.push({flightId:flightId, subject:subject})
	}
}

export class StatsStore {
	private appStore: AppStore
	private _statsData = new StatsData()

	constructor(appStore: AppStore) {
		this.appStore = appStore
		this.getFromLocalStorage()
		makeAutoObservable(this);
	}

	public CollectStatsForCompletedFlight(flightId: string) {
		const flight = this.appStore.flightStore.getFlight(flightId)
		this._statsData.CollectStatsForCompletedFlight(flightId, flight.subject)
		this.updateStorage()
	}

	public CollectStatsForRecognitionResult(result: RecognitionResult) {
		this._statsData.CollectStatsForRecognitionResult(result)
		this.updateStorage()
	}
	
	public SubjectCompletionPercentage(subject: string) {
		
		var numFlightsWithSubject = this.appStore.flightStore.flights.filter(f => subject && f && f.subject && f.subject.toLowerCase() === subject.toLowerCase()).length
		var numFlightsCompleted = this._statsData.flights.filter(f => subject && f && f.subject && f.subject.toLowerCase() === subject.toLowerCase()).length
		if (numFlightsWithSubject === 0) return 0
		return Math.round(numFlightsCompleted / numFlightsWithSubject * 100)
	}
	
	public getFromLocalStorage() {
		this._statsData.fromJson(JSON.parse(localStorage.getItem("cavok.stats") || "{}"))
	}
	
	public updateStorage() {
		localStorage.setItem("cavok.stats", JSON.stringify(this._statsData))
	}
	
	public clearStorage() {
		localStorage.removeItem("cavok.stats")
	}
}