import React from "react";
import {motion} from "framer-motion"
import {uiState} from "../pages/flightPage/flightPageModel";
import styles from "./soundWaveListItem.module.css";
import AppStore from "../../stores/appStore";
import {observer} from "mobx-react";
import {waveStoreState} from "../../stores/waveStore";

export const SoundWaveListItem = observer((props: any) => {

	const waveStore = AppStore.getInstance().waveStore

	window.addEventListener('keydown', (event:any) => {
		waveStore.handlePttDown(event)
	})
	
	window.addEventListener('keyup', (event:any) => {
		waveStore.handlePttUp(event)	
	})
	
	return (<motion.li key={crypto.randomUUID()}
			   initial={{y: 0, opacity: 1}}
			   animate={{y: 0, opacity: 0.2}}
			   className={styles.wavListItem}>
		<div className={styles.soundByte + ' ' + styles.soundByteHeight}></div>
		<SoundWaveListItemContent></SoundWaveListItemContent>
	</motion.li>)
})

const SoundWaveListItemContent = observer((props: any) => {

	const model = AppStore.getInstance().uiStore.flightPageModel
	const waveStore = AppStore.getInstance().waveStore

	if (model.uiState == uiState.waitingForButton) return null

	//Recording! Show the wave
	if (waveStore.wave && waveStore.state === waveStoreState.recording) {
		return (
			<>
				{waveStore.wave.map((b) => {
					var v = (b * 0.1)
					return (<div key={crypto.randomUUID()} className={styles.soundByte} style={{height: v}}></div>)
				})}
			</>
		)
	}

	if (model.uiState == uiState.waitingForRecognizer) {
		return (
			<>
				{waveStore.wave.map(b => {
					var v = Math.floor(Math.random() * 5)
					return (<div key={crypto.randomUUID()} className={styles.soundByte}
								 style={{height: v, backgroundColor: "lightgrey"}}></div>)
				})}
			</>
		)
	}

	if (model.uiState == uiState.speaking) {

		return (
			<>
				{Array.from({length: 255}, (v, i) => i).map(b => {
					var v = Math.floor(Math.random() * 15)
					return (<div key={crypto.randomUUID()} className={styles.soundByte} style={{height: v}}></div>)
				})}
			</>
		)
	}

	if (model.uiState == uiState.waitingForAtx) {

		return (
			<span>
				Hold [T] to talk
			</span>
		)
	}


	return null


})