import { observer } from "mobx-react";
import React from "react";
import { NavButton } from "../../common/navButton";
import styles from './mainMenuPage.module.css';
import common from '../../common.module.css'

export const MainMenuPage = observer(() => {
    return (
        <div className={common.page + ' ' + styles.page + ' ' + styles.navButtonStack}>
            <h1 className={styles.padding}>Select your topic</h1>
            <NavButton bg="bg1" heading="Welcome" subheading="If this is your first, lets get you set up for some ATC radiocommunications"
                link={'/welcome'} subject={'welcome'} />
            <NavButton bg="bg2" heading="Ground school" subheading="Cover the basics before we get airborne" link={'/ground'} subject={'ground'} />
            <NavButton bg="bg3" heading="Navigation flight" subheading="Practive what you learned with realistic ATC communication" link={'/flights'} subject={'navigation'} />

        </div>
    );
});