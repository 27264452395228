import React from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion"
import AppStore from "../../stores/appStore";
import {PlayButton} from "./buttons";
import styles from "./atxListItem.module.css";
import {MatchText} from "./matchText";
import common from "../common.module.css";
import {ScoreCircle} from "./scoreCircle";

export const AtxListItem = observer((props: any) => {

	const model = AppStore.getInstance().uiStore.flightPageModel

	if (!props.result.isUsed) return null
	if (!model.variables.showAtx) return null
	return (<motion.li key={crypto.randomUUID()}
					   initial={{y: -10, opacity: 0}}
					   animate={{y: 0, opacity: 1}}
					   exit={{y: -20, opacity: 0}}
					   className={common.listitem + ' ' + styles.listitem}>

		<AtxListItemContent result={props.result}></AtxListItemContent>
	</motion.li>)
})

const AtxListItemContent = observer((props: any) => {

	const model = AppStore.getInstance().uiStore.flightPageModel
	
	return (<>

		<ScoreCircle key={crypto.randomUUID()} value={props.result?.recognitionResult?.score}/>
		<div key={crypto.randomUUID()} className={common.result}>
			{props.result?.recognitionResult?.matches ? props.result?.recognitionResult?.matches.map((m) => {
				return <MatchText key={crypto.randomUUID()} match={m}/>
			}) : <span className={common.text}>{props.result.toDisplay}</span>}
		</div>
		

	</>)
	
	
})