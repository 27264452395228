import { action, makeAutoObservable } from "mobx";
import AppStore from "./appStore";

export class VoiceStore {

    appStore: AppStore
    
    constructor(appStore: AppStore) {
        this.appStore = appStore
        speechSynthesis.addEventListener('voiceschanged', () => {
            let temp = speechSynthesis.getVoices();
            let v = temp.map((e) => new Voice(e))
            this.setVoices(v);
        })
        makeAutoObservable(this)
    }

    private setVoices(v: Voice[]) {
        this._voices = v;
    }

    public get ready() {
        return this._voices.length > 0
    }

    private codesForLang(lang: string = 'en') {
        switch(lang) {
            case 'en':
                return ['en-us', 'en-uk']
            case 'es':
                return ['es-es', 'es-mx']
            case 'fr':
                return ['fr-fr', 'fr-ca']
            case 'de':
                return ['de-de']
            case 'it':
                return ['it-it']
            case 'ja':
                return ['ja-jp']
            case 'ko':
                return ['ko-kr']
            case 'zh':
                return ['zh-cn', 'zh-tw']
            default:
                return ['en-us', 'en-uk']
        }
    }
    
    public voicesForLang(lang: string = 'en') {
        const l = this.codesForLang(lang.toLowerCase());
        let v = this._voices.filter(e => l.includes(e.voice.lang.toLowerCase()))
        return v || []
    }

    public get pitch() {
        return 0.4;
    }

    private _voices: Voice[] = [];
   
}

export class Voice {
    constructor(voice: SpeechSynthesisVoice) {
        this.voice = voice
        makeAutoObservable(this)
    }
    voice: SpeechSynthesisVoice
    id: string = crypto.randomUUID()
}
