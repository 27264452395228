import React from "react";
import styles from './header.module.css';
import common from '../common.module.css';
import {observer} from "mobx-react";
import AppStore from "../../stores/appStore";

export const Header = observer((props: any) => {

	const identStore = AppStore.getInstance().identStore

	return (<div className={styles.header + ' ' + common.noPrint}>
		<div className={styles.logoContainer}>
			<span>
				<a href={'/'}><span className={styles.logo1}>CAV</span><span className={styles.logo2}>OK</span></a>
			</span>
			<span className={styles.logo3}>your PPL voice trainer</span>
		</div>

		{identStore.IdentData.isAuthenticated && (<a href={'/account'}>
			<div className={styles.profileContainer}>
				<p>{identStore.IdentData.name}</p>
				<img className={styles.image} alt={'avatar'} src={identStore.IdentData.picture}></img>
			</div>
		</a>)}
	</div>)
})
