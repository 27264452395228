import React from "react";
import styles from './flightPlan.module.css';
import common from "../common.module.css";
export function FlightPlan(props: any) {
    if (props.flp == null || props.flp.length == 0) return null
    return (
        <div className={''}>
            <div key="fph" className={styles.flightPlanHeader + ' ' + common.tileLevelTwo}>
                <span key="cs" className={styles.text}>{props.head.callsign}</span>
                <span key="typ" className={styles.text}>{props.head.acftType}</span>
                <span key="vfr" className={styles.text}>VFR</span>
                <span key="dep" className={styles.text}>{props.head.departure} - {props.head.arrival}</span>
            </div>
            <div className={styles.tableContainer}>
            <table className={styles.flightPlanTable} >
                <thead>
                <tr>
                    <th>AWY</th>
                    <th colSpan={2}>PLACE</th>
                    <th>ALT</th>
                    <th>EET</th>
                    <th>ETO</th>
                    <th>ATO</th>
                </tr>
                </thead>
                <tbody>
                    {props.flp.map((p) => <FlightPlanLine key={crypto.randomUUID()}  line={p.toDisplay}/>)}
                </tbody>
            </table>
            </div>
            <div className={styles.flightPlanFooter + ' ' + common.tileLevelTwo}>
                {props.head.txt == null || props.head.txt.length == 0 ? " - " : ""}
                {props.head.txt.split('#').map((t) => {return <span key={crypto.randomUUID()}>{t}</span>})}
            </div>
        </div>)
}

export function FlightPlanLine(props: { line: string }) {
    return (
        <tr>
            <td><div><textarea/></div></td>
            {props.line.split('#').map((m) => {
                return (<td key={crypto.randomUUID()}>{m}</td>)
            })}
        </tr>
    )

}