import React, {Component} from "react";
import {observer} from "mobx-react";
import { motion } from "framer-motion"
import styles from './buttonListItem.module.css';
import AppStore from "../../stores/appStore";
import common from "../common.module.css";

@observer
export default class ButtonListItem extends Component<any> {
    private model = AppStore.getInstance().uiStore.flightPageModel

    constructor(props: any)
    {
        super(props)
    }
    
    render() {
        if (this.props.href) {
            return <li
                className={common.listitem + ' ' + styles.listitem}>
                <div className={this.props.enabled ? styles.enabled : styles.disabled}>
                    <a className={styles.text} href={this.props.href}>{this.props.text}</a>
                </div>
            </li>        
        }
        
        return <li
            className={common.listitem + ' ' + styles.listitem}>
            <div className={this.props.enabled ? styles.enabled : styles.disabled}>
                <span className={styles.text} onClick={this.props.enabled ? () => { this.model.btnElementClick() }: null} >{this.props.text}</span>
            </div>
        </li>
    }
}