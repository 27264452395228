import { observer } from "mobx-react";
import AppStore from "../../../stores/appStore";
import common from '../../common.module.css'
import React from "react";
import { runInAction } from "mobx";

export const AccountPage = observer(() => {

	const identStore = AppStore.getInstance().identStore

	return (<div className={common.page}>
		<div className={common.pageHeader}>
			<h1>Hi, {identStore.IdentData.name}</h1>
			<p>At <b>CAV</b>OK we only collect the information necessary to identify you, so you can save your progress.
			</p>
		</div>

		<div className={common.tileLevelOne}>
			<h2>Log out</h2>
			<p>Use this link to log out of your <b>CAV</b>OK account</p>
			<a onClick={() => { identStore.logOut() }} href={'/'}>Log out</a>
		</div>

		<div className={common.tileLevelOne}>
			<a href={'/menu'}><h3>Back to safety</h3></a>
		</div>
	</div>)
})