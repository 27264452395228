import React from "react";
import {observer} from "mobx-react";
import {motion} from "framer-motion"
import {PlayButton} from "./buttons";
import AppStore from "../../stores/appStore";
import styles from "./gtxListItem.module.css";
import common from "../common.module.css";

export const GtxListItem = observer((props: any) => {
	const model = AppStore.getInstance().uiStore.flightPageModel

	if (!props.result.isUsed) return null
	if (!model.variables.showGtx) return null

	return <motion.li
		initial={{y: -10, opacity: 0}}
		animate={{y: 0, opacity: 1}}
		exit={{y: -20, opacity: 0}}
		className={common.listitem + ' ' + styles.listitem}
		key={crypto.randomUUID()}>
		<div className={styles.result}>
			{props.result.shorthand && props.result.shorthand.split('#').map((m) => {
				return model.variables.showShorthand &&
					<span key={crypto.randomUUID()} className={common.text}>{m}.&nbsp;&nbsp;</span>;
			})}
			{props.result.toDisplay.split('#').map((m) => {
				return model.variables.showGtxText &&
					<span key={crypto.randomUUID()} className={common.text}>{m}.&nbsp;&nbsp;</span>;
			})}
		</div>
		
		{props.replay && (
		<PlayButton icon='play_circle' click={() => {
			model.replay(props.result.toSpeak)
		}}/>)}

	</motion.li>;
})



