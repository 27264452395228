import {autorun, makeAutoObservable, runInAction} from 'mobx';
import AppStore from './appStore';
import FlightPageModel from "../components/pages/flightPage/flightPageModel";
import {Flight} from "../domainModel/flight";
import appStore from "./appStore";
import {WaveStore, waveStoreState} from "./waveStore";

export class UiStore {
    public activeFlightId: string 
    public appStore: AppStore
    public showAtx: 'none' | 'summary' |'full' = 'full'
    public showGnd: 'none' | 'full' = 'full'
    public soundVisualization: any
    public flightPageModel: FlightPageModel
    private waveStore: WaveStore
    constructor(appStore: AppStore) {
        this.appStore = appStore
        this.waveStore = appStore.waveStore
        const json = localStorage.getItem("cavok.flight")
        if (json) {
            this.flightPageModel = JSON.parse(json)    
        } else {
            this.flightPageModel = new FlightPageModel()
        }
        makeAutoObservable(this)
    }
    
    get isRecording() {  return this.waveStore.recording }
    
    setActiveFlight(flight: Flight) {
        if (flight) runInAction(() => {this.flightPageModel.fromFlight(flight)})
    }
    
    setWelcomeFlight() {
        const f = this.appStore?.flightStore?.welcomeFlight()
        this.setActiveFlight(f)
    }
}

