import {makeAutoObservable, runInAction, toJS} from 'mobx';
import {
	Flight,
	Interaction,
	RecognitionResult
} from "../../../domainModel/flight";
import AppStore from "../../../stores/appStore";
import {} from "mobx";

/*
* This state machine works like this:
* 1) Fetch the next line in the flight (currentInteraction)
* 2) Set the "state" based on this (like waiting for the user to speak or the system to read a ground message GTX)
* 3) ProcessCurrentInteraction - will trigger "Ground" actions, like give instructions or make a delay. If waiting for 
* user input, this will do nothing.
* 4) A call to HandleResult will try to process voice interaction from the user.
 */

export enum uiState {
	noFlight,
	recording,
	waitingForRecognizer,
	speaking,
	waitingForAtx,
	waitingForGtx,
	waitingForButton,
	flightEnded,
	inBetweenStates
}

export class FlightVariables {
	showAtxText: boolean = true
	showGtxText: boolean = true
	showAtx: boolean = true
	showGtx: boolean = true
	showShorthand: boolean = false
	breakOnError: boolean = true
	showReplay: boolean = true
	air: string = ""
	gnd: string = ""
	dep: string = ""
	arr: string = ""
	acftType: string = ""
	flightDescription: string = ""
	lang:string = "en"

	constructor() {
		makeAutoObservable(this)
	}

	tryParse(interaction: Interaction) {
		if (!interaction) return
		if (!interaction.toDisplay) return
		const keyval = interaction.toDisplay.split("=")
		if (keyval.length !== 2) return
		const key = keyval[0].toLowerCase()
		const val = keyval[1]
		switch (key) {
			case "air":
				this.air = val
				break
			case "gnd":
				this.gnd = val
				break
			case "dep":
				this.dep = val
				break
			case "arr":
				this.arr = val
				break
			case "acfttype":
				this.acftType = val
				break
			case "flightdescription":
				this.flightDescription = val
				break
			case "showgtx":
				this.showGtx = val.toLowerCase() === "true"
				break
			case "showgtxtext":
				this.showGtxText = val.toLowerCase() === "true"
				break
			case "showatx":
				this.showAtx = val.toLowerCase() === "true"
				break
			case "showatxtext":
					this.showAtxText = val.toLowerCase() === "true"
					break
			case "replay":
				this.showReplay = val.toLowerCase() === "true"
				break
			case "breakonerror":
				this.breakOnError = val.toLowerCase() === "true"
				break
			case "voice":
				AppStore.getInstance().speechSynthStore.pickRandomVoice(this.lang)
				break
			case "lang":
				this.lang = val
				AppStore.getInstance().speechSynthStore.pickRandomVoice(this.lang)
				break
			default:
				break
		}
	}
}

export default class FlightPageModel {
	public interactionsOnScreen: Interaction[] = []
	private _uiState: uiState = uiState.noFlight

	public variables: FlightVariables

	private _nextFlightInteractionIndex: number = 0
	public flight: Flight
	private _currentInteraction: Interaction
	public latestGtxToSayAgain: Interaction


	constructor() {
		this.interactionsOnScreen = []
		this._currentInteraction = null
		this.variables = new FlightVariables()
		this.uiState = uiState.noFlight
		this.latestGtxToSayAgain = null

		makeAutoObservable(this)
	}

	public get flightPlanHeading() {
		const callsign = this.variables.air
		const departure = this.variables.dep
		const arrival = this.variables.arr
		const acftType = this.variables.acftType
		const txt = this.variables.flightDescription

		return {
			callsign: callsign,
			departure: departure,
			arrival: arrival,
			acfType: acftType,
			txt: txt
		}
	}

	public get currentInteraction() {
		return this._currentInteraction
	}

	public get uiState() {
		return this._uiState
	}

	public set uiState(value) {
		this._uiState = value
	}

	public get callsign() {
		return this.flight.flightInfo.find((f) => f.kind === "AIR")?.toDisplay
	}

	fromFlight(flight: Flight) {
		this.flight = flight.clone()
		this.interactionsOnScreen = []
		this._currentInteraction = null
		this.moveRestart()
	}

	public moveRestart() {
		runInAction(() => {
			this.interactionsOnScreen = []
			this._currentInteraction = this.flight.interactions[this._nextFlightInteractionIndex].clone()
			this._nextFlightInteractionIndex++
			this.uiState = this.getStateFromKind(this.currentInteraction.kind)
			if (this.uiState === uiState.waitingForAtx) AppStore.getInstance().waveStore.currentinteractionId = this.currentInteraction.interactionId
		})

		//first interaction can be a gtx, so start processing
		this.processCurrentIfGTXInteraction()
	}

	assesPerformanceIfFLightEnded() {
		if (this.uiState !== uiState.flightEnded) return
		AppStore.getInstance().statsStore.CollectStatsForCompletedFlight(this.flight.flightId)
	}

	//moves the state machine to the next state if inBetweenStates. 
	moveNext(fast: boolean) {

		window.setTimeout(() => {
			runInAction(() => {
				if (this.uiState !== uiState.inBetweenStates) return;

				//push the current interaction into the list ...
				this.currentInteraction.isUsed = true
				this.interactionsOnScreen.push(this.currentInteraction)
				this._currentInteraction = null

				//and find the next interaction in the flight
				if (this.flight.interactions.length > this._nextFlightInteractionIndex) {
					this._currentInteraction = this.flight.interactions[this._nextFlightInteractionIndex++].clone()
				}

				//and set the state based on what interaction is current
				if (this.currentInteraction) {
					this.uiState = this.getStateFromKind(this.currentInteraction.kind)
					if (this.uiState === uiState.waitingForAtx) AppStore.getInstance().waveStore.currentinteractionId = this.currentInteraction.interactionId
				} else {
					this.uiState = uiState.flightEnded
				}

			})
			this.processCurrentIfGTXInteraction()
			this.assesPerformanceIfFLightEnded()
		}, fast ? 0 : 800)
	}

	movePrevious(read: boolean) {
		runInAction(() => {
			this.interactionsOnScreen.pop()
			this.uiState = this.getStateFromKind(this.currentInteraction.kind)
		})
		if (read) this.processCurrentIfGTXInteraction()
	}

	private getStateFromKind(kind: string) {
		if (kind === "ATX" || kind === "RBK" || kind === "PRC" || kind === "PRU") return uiState.waitingForAtx
		if (kind === "GTX" || kind === "DLY" || kind === "END" || kind === "TXT" || kind === "SET") return uiState.waitingForGtx
		if (kind === "BTN") return uiState.waitingForButton
		return uiState.inBetweenStates
	}

	//handles speech recognition result
	public handleAtxSpeechRecognitionResult(resultJson: any) {

		const result = RecognitionResult.fromJson(resultJson)
		 AppStore.getInstance().statsStore.CollectStatsForRecognitionResult(result)
		
		//movenext will only trigger if this is set to inBetweenStates
		//also prevents most other things from proceeding
		this._uiState = uiState.inBetweenStates

		if (result.isValid && this.currentInteraction && result.kind !== "SAG") {

			//something was said but it may be less than perfect
			//attach the result to the current interaction
			this.currentInteraction.recognitionResult = result
			this.currentInteraction.isUsed = true
			//	this.collectStats(result)

			//happy day. good enough result, move next, done
			if (result.score === 100 || !this.variables.breakOnError) {
				this.moveNext(false)
				return
			}
		}

		if (result.isValid && result.kind === "SAG") {
			this.atxSayAgain(result)
			return
		}

		//prepare the pointers so the flow will continue after we try a new recognition
		if (result.isValid && result.score < 100) {
			this.interactionsOnScreen.push(this.currentInteraction)
			this.gtxSayAgain()
			return
		}

		//Do not move the _currentInteraction ATX on screen since it contains gibberish
		//prepare the pointers so the flow will continue after we try a new recognition
		if (!result.isValid) {
			this.gtxSayAgain()
			return
		}
	}

	atxSayAgain(result: RecognitionResult) {
		const atx = new Interaction()
		atx.kind = "ATX"
		atx.toDisplay = "Say again"
		atx.recognitionResult = result.clone()
		atx.isUsed = true

		this.interactionsOnScreen.push(atx)

		if (!this.latestGtxToSayAgain) {
			//crap situation. give up
			this.gtxSayAgain()
			return
		}

		let newGtxElement = this.latestGtxToSayAgain.clone()
		if (!newGtxElement) return

		newGtxElement.isUsed = false
		this._currentInteraction = newGtxElement
		this._nextFlightInteractionIndex--
		this.uiState = this.getStateFromKind("GTX")
		this.processCurrentIfGTXInteraction()
	}

	gtxSayAgain() {
		if (this._currentInteraction?.kind === "RBK") {
			let newGtxElement = this.latestGtxToSayAgain.clone()
			newGtxElement.toSpeak = "Negative, i say again: " + newGtxElement.toSpeak
			newGtxElement.isUsed = false
			this._currentInteraction = newGtxElement
		} else {
			let newGtxElement = new Interaction()
			newGtxElement.kind = "GTX"
			newGtxElement.toSpeak = "Station calling, i reed you one. Say again?"
			newGtxElement.toDisplay = "Station calling, i read you one. Say again!"
			this._currentInteraction = newGtxElement
		}
		this._nextFlightInteractionIndex--
		this.uiState = this.getStateFromKind("GTX")
		this.processCurrentIfGTXInteraction()
	}

	endSpeak() {
		runInAction(() => {
			this.uiState = uiState.inBetweenStates
			this.currentInteraction.isUsed = true
		})
	}

	beginSpeak() {
		this.uiState = uiState.speaking
	}

	public speak(message: string) {
		return new Promise((resolve) => {
			this.beginSpeak()
			AppStore.getInstance().speechSynthStore.say(message).then(() => {
				this.endSpeak()
				resolve(null)
			})
		})
	}

	public replay(message: string) {
		let saveState = this.uiState
		this.uiState = uiState.inBetweenStates
		AppStore.getInstance().speechSynthStore.say(message).then(() => {
			this.uiState = saveState
		})
	}


	private processCurrentIfGTXInteraction() {
		if (!this.currentInteraction) return;
		let state = this.uiState
		let kind = this.currentInteraction.kind

		if (state !== uiState.waitingForGtx) return

		if (kind === "DLY") {
			this.beginSpeak()
			window.setTimeout(() => {
				this.endSpeak()
				this.moveNext(false)
			}, 3000);
			return
		}

		if (kind === "GTX") {
			this.latestGtxToSayAgain = this.currentInteraction.clone()
		}

		if (kind === "GTX" || kind === "END") {
			this.speak(this.currentInteraction.toSpeak).then(() => {
				this.moveNext(false)
			})
			return
		}

		if (kind === "TXT") {
			this.beginSpeak()
			this.endSpeak()
			this.moveNext(false)
			return
		}

		if (kind === "SET") {
			this.variables.tryParse(this.currentInteraction)
			this.beginSpeak()
			this.endSpeak()
			this.moveNext(true)
			return;
		}
	}

	public btnElementClick() {
		if (this.currentInteraction.kind !== "BTN") return
		runInAction(() => {
			this.uiState = uiState.inBetweenStates
		})
		this.moveNext(false)
	}
}


