import {action, makeAutoObservable} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import AppStore from "../../../stores/appStore";
import FlightPage from "../flightPage/flightPage";
import styles from './welcomePage.module.css';
import common from '../../common.module.css'

export class WelcomePageModel {
    public step: number = 0

    public constructor() {
        makeAutoObservable(this)
    }

    @action
    public next() {
        this.step++
    }
}

export const WelcomePage = observer(() => {

    AppStore.getInstance().uiStore.setWelcomeFlight()

    return <div >
        <div className={common.header}>
            <h1>Welcome to cavok the ppl voice trainer</h1>
            <h2>Before we begin we need to make sure your sound and microphone are working. Turn on your sound. When you
                are ready, press the button. We will test that you can receive and transmit as needed.</h2>
        </div>
        <FlightPage></FlightPage>
    </div>
})
 