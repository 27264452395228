import {observer} from "mobx-react";
import {NavButton} from "../../common/navButton";
import React from "react";
import common from "../../common.module.css";
import styles from "../mainMenuPage/mainMenuPage.module.css";
import {FlightSelectList} from "../../common/flightSelectList";

export const PositionPage = observer(() => {
	return (
		<div className={common.page + ' ' + styles.page + ' ' + styles.navButtonStack}>
			<div className={common.pageHeader}>
				<h1>Practice the position reports in controlled and uncontrolled airspace</h1>
				<p>In uncontrolled airspace, the position report consist of these items:</p>
				<ul>
					<li>The initial call signs: ground station then your aircraft call sign. E.g.:<i>Copenhagen information, OY-BGN</i> </li>
					<li>Your last known position and actual time (ATO) of passage. E.g.:<i>Samsø 42</i></li>
					<li>Your altitude and change in altitude, e.g.:<i>2000ft climbing to FL55</i></li>
					<li>The following position and expected time, e.g:<i>Romeo Kilo 05</i></li>
					<li>The next position without any time estimate. Usually followed by the word "next" e.g. <i>Roskilde next</i> </li>
				</ul>
				<p>In controlled airspace, altitude changes, entering airspaces etc. require a clearance from ATC. Therefore this comes after the normal report. Example:</p>
				<ul>
					<li>The initial call signs: ground station then your aircraft call sign. E.g.:<i>Roskilde Tower, OY-BGN</i> </li>
					<li>Your last known position and actual time (ATO) of passage. E.g.:<i>Sejerø 42</i></li>
					<li>Your altitude and change in altitude, e.g.:<i>FL55</i></li>
					<li>The following position and expected time, e.g:<i>Trano VOR 04</i></li>
					<li>The next position without any time estimate. Usually followed by the word "next" e.g. the locator RK:<i>Romeo Kilo next</i> </li>
					<li>Now comes the requests:<i>Request decent to 3000ft and enter the TMA</i> </li>
				</ul>
				<p>In these exercises you will speak to the same ATC for the duration of the "flight". In later exercises, position reports will be mixed up with handovers to other ATC units and other instructions.</p>
			</div>
			<FlightSelectList subject="position"/>
		</div>
	);
});
