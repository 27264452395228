import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { FlightStore } from './flightStore';
import { UiStore } from './uiStore';
import { WaveStore } from "./waveStore";
import { SpeechSynthStore } from "./speechSynthStore";
import { VoiceStore } from "./voiceStore";
import { IdentStore } from "./identStore";
import { StatsStore } from "./statsStore";

export class AppStore {
  private static instance: AppStore;

  identStore: IdentStore
  flightStore: FlightStore
  uiStore: UiStore
  waveStore: WaveStore
  speechSynthStore: SpeechSynthStore
  voiceStore: VoiceStore
  statsStore: StatsStore

  public static getInstance(): AppStore {
    if (!AppStore.instance) {
      AppStore.instance = new AppStore()
    }

    return AppStore.instance
  }

  constructor() {
    this.identStore = new IdentStore(this)
    this.flightStore = new FlightStore(this)
    this.uiStore = new UiStore(this)
    this.waveStore = new WaveStore(this)
    this.speechSynthStore = new SpeechSynthStore(this)
    this.voiceStore = new VoiceStore(this)
    this.statsStore = new StatsStore(this)
    makeAutoObservable(this)
  }
}

export default AppStore;

