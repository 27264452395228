import {makeAutoObservable} from "mobx";

export class Flight {
	flightId: string
	flightInfo: FlightElement[]
	interactions: Interaction[]
	flightPlan: Interaction[]
	subject: string
	key: any

	constructor() {
		this.key = crypto.randomUUID()
		makeAutoObservable(this)
	}

	static FromJson(source:any)
	{
		const result = new Flight()
		result.flightId = source.flightId
		result.subject = source.subject
		result.flightInfo = FlightElement.fromJsonArray(source.flightInfo)
		result.interactions = Interaction.fromJsonArray(source.interactions)
		result.flightPlan = Interaction.fromJsonArray(source.flightPlan)
		return result
	}
	
	clone(): Flight {
		const result = new Flight()
		result.flightId = this.flightId
		result.flightInfo = cloneFlightElementArray(this.flightInfo)
		result.interactions = cloneInteractionsArray(this.interactions)
		result.flightPlan = cloneInteractionsArray(this.flightPlan)
		result.subject = this.subject
		return result
	}
}

export class FlightElement {
	value: string
	toSpeak: string
	toRecognize: string
	toDisplay: string
	kind: string
	key: any

	constructor() {
		this.key = crypto.randomUUID()
		makeAutoObservable(this)
	}

	clone(): FlightElement {
		const result = new FlightElement
		result.value = this.value
		result.toSpeak = this.toSpeak
		result.toRecognize = this.toRecognize
		result.toDisplay = this.toDisplay
		result.kind = this.kind
		return result
	}
	
	static fromJson (source:any): FlightElement
	{
		const result = new FlightElement
		result.value = source.value
		result.toSpeak = source.toSpeak
		result.toRecognize = source.toRecognize
		result.toDisplay = source.toDisplay
		result.kind = source.kind
		return result	
	}
	
	static fromJsonArray (source: any[]): FlightElement[]
	{
		if (source === undefined) return null
		const result: FlightElement[] = []
		for (let i = 0; i < source.length; i++)
			result.push( FlightElement.fromJson(source[i]))
		return result
	}
}

export class Interaction {
	kind: string
	toSpeak: string
	toDisplay: string
	toRecognize: string
	interactionId: string
	recognitionResult: RecognitionResult
	isUsed: boolean
	key: any

	constructor() {
		this.key = crypto.randomUUID()
		makeAutoObservable(this)
	}

	clone(): Interaction {
		const result = new Interaction()
		result.kind = this.kind
		result.toSpeak = this.toSpeak
		result.toDisplay = this.toDisplay
		result.toRecognize = this.toRecognize
		result.interactionId = this.interactionId
		result.recognitionResult = this.recognitionResult?.clone()
		result.isUsed = this.isUsed
		return result
	}

	static fromJson (source:any): Interaction
	{
		const result = new Interaction
		result.kind = source.kind
		result.toSpeak = source.toSpeak
		result.toDisplay = source.toDisplay
		result.toRecognize = source.toRecognize
		result.interactionId = source.interactionId
		result.recognitionResult = null
		result.isUsed = false
		return result
	}
	static fromJsonArray (source: any[]): Interaction[]
	{
		if (source === undefined) return null
		const result: Interaction[] = []
		for (let i = 0; i < source.length; i++)
			result.push( Interaction.fromJson(source[i]))
		return result
	}
}

export class RecognitionResult {
	expected: string
	matches: MatchItem[]
	score: number
	isValid: boolean
	kind: string
	key: any

	constructor() {
		this.key = crypto.randomUUID()
		makeAutoObservable(this)
	}

	static fromJson (source:any): RecognitionResult
	{
		const result = new RecognitionResult
		result.expected = source.expected
		result.matches = MatchItem.fromJsonArray(source.matches)
		result.score = source.score
		result.isValid = source.isValid
		result.kind = source.kind
		return result
	}
	
	clone(): RecognitionResult {
		const result = new RecognitionResult()
		result.expected = this.expected
		result.matches = this.matches
		result.score = this.score
		result.isValid = this.isValid
		result.kind = this.kind
		return result
	}
}

export class MatchItem {
	toDisplay: string
	score: number
	kind: string
	key: any

	constructor() {
		this.key = crypto.randomUUID()
		makeAutoObservable(this)
	}

	clone(): MatchItem {
		const result = new MatchItem()
		result.toDisplay = this.toDisplay
		result.score = this.score
		result.kind = this.kind
		return result
	}

	static cloneArray(source: MatchItem[]): MatchItem[] {
		if (source === undefined) return null
		let result = []
		for (let i = 0; i < source.length; i++)
			result.push(source[i].clone())
		return result
	}

	static fromJson(source: any): MatchItem {
		const result = new MatchItem()
		result.toDisplay = source.toDisplay
		result.score = source.score
		result.kind = source.kind
		return result
	}

	static fromJsonArray(source: any[]): MatchItem[] {
		if (source === undefined) return null
		let result = []
		for (let i = 0; i < source.length; i++)
			result.push(MatchItem.fromJson(source[i]))
		return result
	}
}

export function cloneFlightElementArray(source: FlightElement[]): FlightElement[] {
	if (source === undefined) return null
	let result = []
	for (let i = 0; i < source.length; i++)
		result.push(source[i].clone())
	return result
}

export function cloneInteractionsArray(source: Interaction[]): Interaction[] {
	if (source === undefined) return null
	let result = []
	for (let i = 0; i < source.length; i++)
		result.push(source[i].clone())
	return result
}


