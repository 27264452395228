import { makeAutoObservable, runInAction } from "mobx";
import AppStore from "./appStore";
import { Flight } from "../domainModel/flight";

//Fetches and stores the available flights from backend
//(flights taken and user progress not stored here)

export class FlightStore {
    private readonly appStore: AppStore
    private readonly apiUrl: string = process.env.REACT_APP_API_URL + '/api/flights';
    private retry: number = 10

    public ready: boolean = false
    public flights: Flight[] = [];

    constructor(appStore: AppStore) {
        this.appStore = appStore
        this.flights = []
        this.fetchFlights()
        makeAutoObservable(this);
    }

    private internalFetchFlightsFromServer() {
        const json = null //localStorage.getItem("cavok.flightstore")
        if (json) {
            const data = JSON.parse(json)
            data.forEach((flight: any) => {
                runInAction(() => {
                    this.flights.push(Flight.FromJson(flight))
                })
            });
            return
        }

        return fetch(this.apiUrl)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem("cavok.flightstore", JSON.stringify(data))
                runInAction(() => {
                    data.forEach((flight: any) => {
                        this.flights.push(Flight.FromJson(flight))
                    })
                })

                runInAction(() => {
                    this.ready = true
                })
            }).catch(err => {
                if (this.retry-- > 0) {
                    window.setTimeout(() => {
                        this.internalFetchFlightsFromServer()
                    }, 5000);
                }
            });
    }

    public fetchFlights() {
        this.retry = 10;
        this.internalFetchFlightsFromServer();
    }

    public welcomeFlight() {
        if (!this.ready) return null
        return this.flights.find(f => f.subject === "Welcome")
    }

    public getFlight(flightId: string) {
        return this.flights.find(f => f.flightId === flightId)
    }

}

